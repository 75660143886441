import React from "react"
import Layout from "../components/Layout"
import ContactMain2 from "../components/ContactMain2"
import Testimonial from "../components/Testimonial"
import SEO from "../components/Seo"

// import ContactMain from "../components/ContactMain"

export default function Contact({ location }) {
  return (
    <Layout>
      <SEO title={"Contact"} pathname={location.pathname} />
      <ContactMain2 />
      <Testimonial />
    </Layout>
  )
}
