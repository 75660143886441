import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function ContactMain2() {
  const data = useStaticQuery(graphql`
    query ContactMain2ImageQuery {
      file(relativePath: { eq: "headShot_1920x1080.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    // Use section tag?
    <section className="relative  bg-gray-50">
      <div className="h-56  sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <Img
          className="w-full h-full object-cover"
          fluid={data.file.childImageSharp.fluid}
          alt="Kristi Adams - Realtor with London Properties"
        />
      </div>
      <div className="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <div className="mt-4 sm:mt-16 md:mt-4">
            <h1 className="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
              Contact Kristi
            </h1>
            <div className="mt-6">
              <p className="text-lg leading-7 text-gray-600">
                Whether it's navigating the way for first timers, finding that
                Dream Home, or locating the perfect income property, I am here
                to guide you through the process and make your aspirations a
                Real Estate reality.
              </p>
            </div>
            <address className="mt-9 not-italic">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg
                    className="h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                </div>
                <div className="ml-3 text-base leading-6 text-gray-600">
                  <p>+1 (559) 385-0443</p>
                  {/* <p className="mt-1">Mon-Fri 8am to 6pm PST</p> */}
                </div>
              </div>
              <div className="mt-6 flex">
                <div className="flex-shrink-0">
                  <svg
                    className="h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                </div>
                <div className="ml-3 text-base leading-6 text-gray-600">
                  <p>kadams@londonproperties.com</p>
                </div>
              </div>
              <div className="mt-6 flex">
                <div className="flex-shrink-0">
                  <svg
                    className="h-6 w-6 text-gray-400"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"></path>
                  </svg>
                </div>
                <div className="ml-3 text-base  leading-6 text-gray-600">
                  <p>Kristi Adams - London Properties</p>
                  <p>1520 Marion St. Kingsburg CA, 93631</p>
                </div>
              </div>
            </address>
          </div>
          {/* <div className="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
            Your local realtor
          </div>
          <h2 className="mt-2 text-white text-3xl leading-9 font-bold tracking-tight sm:text-4xl sm:leading-10">
            Hi, I'm Kristi!
          </h2>
          <p className="mt-3 text-lg leading-7 text-gray-300">
            Thinking of buying or selling a home? Let me connect you with others
            in the market!
          </p> */}
          {/* <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="/contact"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
              >
                Contact
                <svg
                  className="-mr-1 ml-3 h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                  <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                </svg>
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}
